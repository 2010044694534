import { z } from 'zod';

export const checkNotEmptyStringSchema = (fields) => {
  const schemaDefinition = {};
  const errorMsg = 'Поле не может быть пустым';
  fields.forEach((field) => {
    schemaDefinition[field] = z.string().min(1, errorMsg);
  });

  return z.object(schemaDefinition);
};

export const datetimeSchema = z.date();

export const typeNumberSchema = z.number();
